import { DestroyRef, Directive, ElementRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, ReplaySubject, switchMap } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import {
  GoogleOAuthCustomWidth,
  GoogleOAuthCustomWidthType,
  GoogleOAuthResponse,
  RenderButtonSize,
  RenderButtonSizeType
} from './google-oauth.model';
import { Google, GoogleOAuthService } from './google-oauth.service';

@Directive({
  selector: '[googleOauth]',
  standalone: true
})
export class GoogleOAuthDirective implements OnInit {
  @Input() public size: RenderButtonSizeType = RenderButtonSize.medium;
  @Input() public locale: string;
  private readonly destroyRef = inject(DestroyRef);
  @Input() private useSignUpText: boolean;
  @Input() private customWidth: GoogleOAuthCustomWidthType = GoogleOAuthCustomWidth.default;
  @Output() private completeLoggedIn = new EventEmitter<GoogleOAuthResponse>();

  private renderButtonSubject = new ReplaySubject<void>(1);

  constructor(
    private service: GoogleOAuthService,
    private el: ElementRef<HTMLElement>,
    private translate: TranslateService
  ) {
  }

  private get renderButtonAction$(): Observable<Google> {
    return this.renderButtonSubject.pipe(
      switchMap(() => this.service.renderButton(this.el.nativeElement, {
        size: 'medium',
        text: this.useSignUpText ? 'signup_with' : 'signin_with',
        width: this.getCustomWidth(this.customWidth),
        height: 40,
        logo_alignment: 'center',
        locale: this.locale ?? this.translate.currentLang
      }))
    );
  }

  private get reRenderButtonAction$(): Observable<number> {
    return this.translate.onLangChange.pipe(
      startWith(0),
      tap(() => this.renderButtonSubject.next()),
      map(() => 0)
    );
  }

  private get tokenResponseAction$(): Observable<GoogleOAuthResponse> {
    return this.service.tokenResponseEvent$.pipe(
      tap(data => this.completeLoggedIn.emit(data))
    );
  }

  public ngOnInit(): void {
    forkJoin([
      this.renderButtonAction$,
      this.reRenderButtonAction$,
      this.tokenResponseAction$
    ]).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private getCustomWidth(customWidth: GoogleOAuthCustomWidthType): number | undefined {
    switch (customWidth) {
      case GoogleOAuthCustomWidth.myInfo:
        return 185;
      case GoogleOAuthCustomWidth.default:
      default:
        return undefined;
    }
  }
}
