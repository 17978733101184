import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';

import { GoogleOAuthDirective } from './google-oauth.directive';
import { GoogleOAuthService } from './google-oauth.service';

@NgModule({
  imports: [
    CommonModule,
    GoogleOAuthDirective
  ],
  exports: [GoogleOAuthDirective]
})
export class GoogleOAuthModule {
  public static forRoot(clientId: string): ModuleWithProviders<GoogleOAuthModule> {
    return {
      ngModule: GoogleOAuthModule,
      providers: [
        GoogleOAuthService,
        { provide: GOOGLE_OAUTH_CLIENT_ID_TOKEN, useValue: clientId }
      ]
    };
  }
}

export const GOOGLE_OAUTH_CLIENT_ID_TOKEN = new InjectionToken<string>('GOOGLE_OAUTH_CLIENT_ID');
