import { DEFAULT_LANGUAGE, Lang } from '@aimmo/i18n';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { ScriptLoader } from '@falcon/common/services';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, defer, EMPTY, Observable, retry, share, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GoogleOAuthResponse, NEW_GOOGLE_SDK_URL } from './google-oauth.model';
import { GOOGLE_OAUTH_CLIENT_ID_TOKEN } from './google-oauth.module';

// todo @types/google.accounts 지정하도록 개선 필요
// https://app.asana.com/0/692598029547572/1204151827055522
export interface Google {
  accounts: {
    id: any; // google.accounts.id => namespace 가져오기
    oauth2: any; // google.accounts.oauth2 => namespace 가져오기
  };
}

declare const google: Google;

@Injectable()
export class GoogleOAuthService {
  private googleSource = new BehaviorSubject<Google>(null);

  private tokenResponseSource = new Subject<GoogleOAuthResponse>();

  private loadModuleShare$: Observable<Google> =
    defer(() => {
      const lang = (this.t.currentLang as Lang) ?? DEFAULT_LANGUAGE;
      return this.loadModule(lang);
    }).pipe(
      catchError(error => {
        alert(this.t.instant('@인증_모듈_로드에_실패하였습니다_M@'));
        this.handler.handleError(error); // share 에서 멀티캐스트로 인해 동시에 여러번 호출될 수 있어서 직접 error handle 호출
        return EMPTY;
      }),
      share()
    );
  private readonly getGoogleSdkJsSrc = (lang: Lang) => `${NEW_GOOGLE_SDK_URL}?hl=${lang}`;

  constructor(
    @Inject(GOOGLE_OAUTH_CLIENT_ID_TOKEN) private clientId: string,
    private scriptLoader: ScriptLoader,
    private t: TranslateService,
    private handler: ErrorHandler
  ) {
    this.loadModuleShare$.subscribe();
  }

  public get tokenResponseEvent$(): Observable<GoogleOAuthResponse> {
    return this.tokenResponseSource.asObservable();
  }

  private get googleItem(): Google {
    return this.googleSource.getValue();
  }

  public logout(): void {
    if (!this.googleItem) {
      return;
    }

    this.googleItem.accounts.id.disableAutoSelect();
  }

  public renderButton(parent: HTMLElement, param: any): Observable<Google> {
    return this.loadModuleShare$.pipe(
      tap(googleItem => googleItem.accounts.id.renderButton(parent, param))
    );
  }

  private loadModule(lang: Lang): Observable<Google> {
    return this.scriptLoader
      .setTimeout(10000)
      .addScriptWithExecute({
        src: this.getGoogleSdkJsSrc(lang)
      })
      .build({ useCache: false }).pipe(
        switchMap(() => this.initGoogle()),
        tap(() => this.googleSource.next(google)),
        map(() => google)
      );
  }

  private initGoogle(): Observable<any> {
    return new Observable(observer => {
      google.accounts.id.initialize({
        client_id: this.clientId,
        callback: data => this.tokenResponseSource.next(data)
      });
      observer.next();
      observer.complete();
    }).pipe(
      retry({ count: 3, delay: 20 })
    );
  }
}
