export interface GoogleOAuthResponse {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
}

export enum GoogleOAuthCustomWidth {
  default = 'default',
  myInfo = 'myInfo'
}

export type GoogleOAuthCustomWidthType = keyof typeof GoogleOAuthCustomWidth;

export const RenderButtonSize = {
  large: 'large',
  medium: 'medium',
  small: 'small'
} as const;

export type RenderButtonSizeType = keyof typeof RenderButtonSize;

export interface RenderButtonParam {
  size: RenderButtonSizeType;
  text: 'signup_with' | 'signin_with';
  width?: number;
}

export const NEW_GOOGLE_SDK_URL = 'https://accounts.google.com/gsi/client';

export class GoogleOAuthLoadError extends Error {}
